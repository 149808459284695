<template>
	<div>
		<div class="w-12/12">
			<div class="flex flex-col xl:w-8/12 mt-5">
				<div class="-my-2 overflow-x-auto sm:-ml-6 lg:-ml-8">
					<div class="flex justify-between flex-row-reverse mb-3 sm:px-6 lg:px-8">
						<v-button
							@click="exportDataToCSV"
							:disabled="exportButtonDisabled || Object.keys(domain_map).length == 0"
							:loading="exportButtonDisabled"
							class="btn btn-small h-10 mr-3"
							v-bind:class="{
								'btn-action': exportButtonDisabled,
								'btn-action-outline': !exportButtonDisabled,
							}"
							>Export all data <span v-html="download_icon" class="ml-3"></span
						></v-button>
					</div>
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											<span v-if="domains.length > 0"
												>{{ tableIndex * tableMaxLength + 1 }}-{{
													Math.min((tableIndex + 1) * tableMaxLength, domains.length)
												}}
												/ {{ domains.length }}</span
											>
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Website
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Data status
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
											Display<br /><span class="font-light lowercase tracking-normal"
												>(Up to 10)</span
											>
										</th>
										<th scope="col" class="relative px-6 py-4 text-center">
											<v-button @click="showAddDomainModal" class="btn btn-add-new btn-small mr-2"
												>Add<span class="ml-3" v-html="plus_icon"></span
											></v-button>
											<v-button
												v-if="!teamId && !projectId && !companyId"
												@click="resetDomains"
												class="btn btn-warning-outline btn-small mr-2"
												>Reset</v-button
											>
										</th>
									</tr>
								</thead>
								<tbody class="bg-white divide-y divide-gray-200">
									<tr
										v-for="(domain, index) in Object.keys(domain_map).slice(
											tableIndex * tableMaxLength,
											(tableIndex + 1) * tableMaxLength
										)"
										:key="domain">
										<td class="px-6 py-1 whitespace-nowrap">
											<div class="text-sm text-gray-900">
												{{ tableIndex * tableMaxLength + index + 1 }}
											</div>
										</td>
										<td class="px-6 py-1 whitespace-nowrap">
											<div class="text-sm text-gray-900">{{ domain }}</div>
										</td>
										<td class="px-6 py-1 whitespace-nowrap">
											<div class="inline-flex">
												<div class="text-xs text-gray-900 font-bold">
													{{ domainsStatusData[domain]?.data_status.toUpperCase() }}
												</div>
												<div v-if="['pending', 'collecting'].includes(domainsStatusData[domain]?.job_status)" class="text-xs text-gray-900 ml-2">
													({{ domainsStatusData[domain]?.job_status.toUpperCase() }})
												</div>
												<span v-if="!['pending', 'collecting'].includes(domainsStatusData[domain]?.job_status) && ['ready', 'error', 'unknown'].includes(domainsStatusData[domain]?.data_status)" class="ml-2 cursor-pointer" @click="restartDomainJob(domain)" v-html="restart_icon"></span>
											</div>
										</td>
										<td class="px-6 py-1 whitespace-nowrap text-center text-sm font-medium">
											<button
												@click="showDomain(domain)"
												class="btn btn-small mr-2"
												v-bind:class="{
													'btn-default': showDomains.includes(domain),
													'btn-default-outline': !showDomains.includes(domain),
												}">
												<span v-html="tick_icon"></span>
											</button>
										</td>
										<td
											class="px-6 py-1 whitespace-nowrap text-center text-sm font-medium flex justify-between">
											<button
												@click="removeDomain(domain)"
												class="btn btn-danger-outline btn-small mr-2">
												Remove
											</button>
											<button
												@click="gotoDRC(domain)"
												class="btn btn-action-outline btn-small mr-2">
												<span v-html="calculator_icon"></span>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="flex justify-between p-3 border-t">
								<v-button
									@click="prevTableIndex"
									:disabled="tableIndex == 0"
									class="btn btn-default-outline"
									>&lt; Prev</v-button
								>
								<v-button
									@click="nextTableIndex"
									:disabled="(tableIndex + 1) * tableMaxLength > domains.length"
									class="btn btn-default-outline"
									>Next &gt;</v-button
								>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!checkingDomains">
					<div v-if="domains.length === 0" class="text-center p-4">
						<span class="text-sm font-bold">Add domains to get started</span>
					</div>
					<div v-if="showDomains.length > maxDataLines">
						<div class="m-5 text-lg text-center font-bold">Too many domains to show</div>
					</div>
					<div v-if="domains.length !== 0 && showDomains.length === 0">
						<div class="m-5 text-lg text-center font-bold">Select domains to display</div>
					</div>
				</div>
				<div v-if="checkingDomains && showDomains.length == 0" class="flex justify-center">
					<div class="text-center mt-5">
						<span v-html="loading_spinner"></span>
					</div>
				</div>
			</div>

			<DomainMetricGroupView
				v-if="showDomains.length > 0 && showDomains.length <= maxDataLines"
				:domains="showDomains"
				:key="domainsKey" />
		</div>
	</div>

	<Modal v-show="addDomainModal" :widthPercent="60" @close="addDomainModal = false">
		<template v-slot:header>
			<h3>Add website</h3>
		</template>

		<template v-slot:body>
			<form class="form-bare">
				<label class="flex flex-col font-bold w-full">
					<span class="mt-2">Websites</span>
					<span class="text-xs font-normal">One website per line</span>
					<textarea
						class="border form-input mt-1 p-2"
						name="name"
						placeholder="Websites"
						rows="4"
						v-model="new_domains"></textarea>
				</label>
				<v-button @click="addDomains" class="btn btn-success">Add websites</v-button>
			</form>
		</template>
	</Modal>
</template>

<script>
import DomainMetricGroupView from "@/components/projects/company-analysis/DomainMetricGroupView";
import Button from "@/components/ui/Button";
import Modal from "@/components/Modal";
import fsischedulerapi from "@/services/api-clients/fsischeduler";
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import moment from "moment";

const maxDataLines = 10;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;
const tick_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>`;
const download_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>`;
const loading_spinner = `<svg class="animate-spin h-8 w-8 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>`;
const calculator_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
					</svg>`;
const restart_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
					</svg>`

const checkArrayUnique = function (arr) {
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}
	return arr.filter(onlyUnique);
};

const pull_ready_data_domains = function (domain_res) {
	return domain_res.filter((d) => d.data_status == "ready").map((d) => d.domain);
};

const pull_non_ready_data_domains = function (domain_res) {
	return domain_res.filter((d) => d.data_status == "pending" || d.data_status == "collecting").map((d) => d.domain);
};

export default {
	created() {},

	mounted() {
		if (this.initialDomains) {
			this.domains = this.initialDomains;
		}
		this.checkDomainData();
	},

	unmounted() {
		if (this.intervalCheck != null) {
			clearInterval(this.intervalCheck);
		}
	},

	components: {
		DomainMetricGroupView,
		"v-button": Button,
		Modal,
	},

	props: ["teamId", "projectId", "companyId", "initialDomains", "companyInfo"],

	data() {
		return {
			addDomainModal: false,
			new_domains: [],
			domains: [],
			domain_map: {},
			showDomains: [],
			domainsKey: 0,
			domainsStatusData: {},
			intervalCheck: null,
			plus_icon,
			tick_icon,
			download_icon,
			loading_spinner,
			calculator_icon,
			restart_icon,
			maxDataLines: maxDataLines,
			exportButtonDisabled: false,
			checkingDomains: false,
			tableIndex: 0,
			tableMaxLength: 10,
		};
	},

	methods: {
		setCheckInterval() {
			if (this.intervalCheck != null) {
				clearInterval(this.intervalCheck);
			}
			let self = this;
			let _checkFunc = function () {
				self.checkDomainData();
			};
			this.intervalCheck = setInterval(_checkFunc, 5000);
		},

		async checkDomainData(force) {
			clearInterval(this.intervalCheck);
			this.checkingDomains = true;

			let non_ready_domains = this.domains;
			if (Object.keys(this.domain_map).length > 0) {
				non_ready_domains = pull_non_ready_data_domains(Object.values(this.domain_map));
			}

			// Add any domains we don't know about
			non_ready_domains = checkArrayUnique([
				...non_ready_domains,
				...this.domains.filter((d) => !(d in this.domain_map)),
			]).filter((d) => d && d.length > 0);

			if (force === true) {
				non_ready_domains = this.domains
			}

			if (non_ready_domains.length > 0 || force === true) {
				let res = await fsischedulerapi.bulkDomainDataCheck(non_ready_domains);
				console.log(res)
				let domain_res = [];
				let domain_map = {};
				for (let i in res.data.domain_data) {
					domain_res.push(res.data.domain_data[i]);
					domain_map[res.data.domain_data[i].domain] = {
						domain: res.data.domain_data[i].domain,
						data_status: res.data.domain_data[i].data_status,
						job_status: res.data.domain_data[i].job_status,
					};
				}

				let foundChange = false;
				let new_domain_map = { ...this.domain_map, ...domain_map };
				if (JSON.stringify(new_domain_map) !== JSON.stringify(this.domain_map)) {
					foundChange = true;
				}
				this.domain_map = new_domain_map;
				console.log(this.domain_map)
				this.domains = Object.keys(this.domain_map);
				this.domains.sort();

				if (foundChange || Object.keys(this.domainsStatusData).length == 0 || force === true) {
					let domainsStatusData = {};
					for (let domain in this.domainsStatusData) {
						if (domain in this.domain_map) {
							domainsStatusData[domain] = this.domainsStatusData[domain];
						}
					}
					for (let i in domain_res) {
						domainsStatusData[domain_res[i].domain] = domain_res[i];
					}
					this.domainsStatusData = domainsStatusData;
					this.domainsKey += 1;
				}

				let ready_domains = pull_ready_data_domains(Object.values(this.domain_map));
				if (this.domains.length <= maxDataLines) {
					this.showDomains = this.domains.filter((d) => ready_domains.includes(d));
				}

				let allReadyOrError = pull_non_ready_data_domains(Object.values(this.domain_map)) == 0;
				if (!allReadyOrError) {
					this.setCheckInterval();
				}
			}
			this.checkingDomains = false;
		},

		showAddDomainModal() {
			this.addDomainModal = true;
		},

		async addDomains() {
			let new_domains_list = this.new_domains.split("\n");
			for (let i in new_domains_list) {
				if (new_domains_list[i].length > 0) {
					this.domains.push(new_domains_list[i]);
				}
			}
			this.domains = checkArrayUnique(this.domains);

			this.new_domains = "";
			this.addDomainModal = false;

			if (this.teamId && this.projectId && this.companyId) {
				await this.updateCompanyDomains(new_domains_list, []);
			}

			this.checkDomainData();
		},

		async removeDomain(domain) {
			this.domains = this.domains.filter((item) => item !== domain);
			this.domains = checkArrayUnique(this.domains);
			delete this.domain_map[domain];

			if (this.teamId && this.projectId && this.companyId) {
				await this.updateCompanyDomains([], [domain]);
			}

			this.checkDomainData();
		},

		resetDomains() {
			if (!this.teamId && !this.projectId && !this.companyId) {
				this.domains = [];
				this.checkDomainData();
			}
		},

		prevTableIndex() {
			this.tableIndex -= 1;
			if (this.tableIndex < 0) {
				this.tableIndex = 0;
			}
		},
		nextTableIndex() {
			this.tableIndex += 1;
			if (this.tableIndex * this.tableMaxLength > this.domains.length) {
				this.tableIndex -= 1;
			}
		},

		async showDomain(domain) {
			if (this.showDomains.includes(domain)) {
				this.showDomains = this.showDomains.filter((curr_domain) => curr_domain != domain);
			} else {
				this.showDomains.push(domain);
			}
			this.domainsKey += 1;
		},

		async restartDomainJob(domain) {
			let res = await fsischedulerapi.restartDomainJob(domain);
			setTimeout(() => {this.checkDomainData(true)}, 500)
			setTimeout(() => {this.checkDomainData(true)}, 5000)
		},

		async updateCompanyDomains(add_domains, remove_domains) {
			try {
				let payload = {
					add_domains: add_domains,
					remove_domains: remove_domains,
				};

				let response = await this.$api
					.resource("companyAnalysisProjectCompany", {
						team_id: this.teamId,
						project_id: this.projectId,
						company_id: this.companyId,
					})
					.put(payload);
				this.domains = response.data.domains;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		gotoDRC(domain) {
			this.$router.push({
				name: "workspace-digital-reference-check",
				props: { teamId: this.teamId },
				query: { drcDomain: domain, showBackButton: true },
			});
		},

		async exportDataToCSV() {
			this.exportButtonDisabled = true;

			// Batch the export to prevent overloading the server
			const MAX_DOMAINS_REQ = 1000;
			let domains = pull_ready_data_domains(Object.values(this.domain_map));
			let domain_entities = [];
			for (let i = 0; i < parseInt(domains.length / MAX_DOMAINS_REQ) + 1; i++) {
				let fsiProfResponse = await fsiprofessorapi.searchDomains(
					domains.slice(i * MAX_DOMAINS_REQ, (i + 1) * MAX_DOMAINS_REQ)
				);
				domain_entities = [...domain_entities, ...fsiProfResponse.data.domain_entities];
			}

			let otherDataPoints = ["company-info", "location", "industrial-classification", "size", "social"];
			let keys = ["domain", "datapoint", "date", "value"];
			let dataPoints = [];

			for (let i in domain_entities) {
				let domain_entity = domain_entities[i];
				for (let j in domain_entity.latest_data_points) {
					let datapoint = domain_entity.latest_data_points[j];
					if (datapoint.data_type == "on-demand-compiled-data-all") {
						continue;
					}

					// Handle digital growth
					if (datapoint.data_type == "on-demand-data-digital-growth-score") {
						for (let k in datapoint.payload.data.trend_ts) {
							let dp = {
								domain: domain_entity.domain,
								datapoint: datapoint.data_type,
								date: moment(datapoint.payload.data.trend_ts[k].date).format("YYYY-MM"),
								value: datapoint.payload.data.trend_ts[k].value,
							};
							dataPoints.push(dp);
						}
					}
					// Handle main compiled data
					else if (
						datapoint.data_type.substring(0, "on-demand-compiled-data".length) === "on-demand-compiled-data"
					) {
						for (let k in datapoint.payload.data) {
							let dp = {
								domain: domain_entity.domain,
								datapoint: datapoint.data_type,
								date: moment(datapoint.payload.data[k].date).format("YYYY-MM"),
								value: datapoint.payload.data[k].value,
							};
							dataPoints.push(dp);
						}
					}
					// Handle other data
					else if (otherDataPoints.includes(datapoint.data_type)) {
						for (let item in datapoint.payload) {
							let dp = {
								domain: domain_entity.domain,
								datapoint: datapoint.data_type + "_" + item,
								date: null,
								value: datapoint.payload[item],
							};
							dataPoints.push(dp);
						}
					}
				}
			}

			// Write the CSV
			let keys_string = keys.join(",");
			let csvContent = "data:text/csv;charset=utf-8," + keys_string + "\r\n";

			dataPoints.forEach(function (dp) {
				let row = [];
				for (var k in keys) {
					let value = "";
					if (dp[keys[k]] != null) {
						value = String(dp[keys[k]]).replaceAll(",", "");
					}
					row.push(value);
				}
				csvContent += row.join(",") + "\r\n";
			});

			var encodedUri = encodeURI(csvContent);
			var link = document.createElement("a");
			link.setAttribute("href", encodedUri);

			let filename = "FSI-DataExport-";
			let name = this.companyInfo.name;
			if (name) {
				name = name.replace(/[^a-zA-Z0-9]/gi, "").toLowerCase();
				filename += name;
			}
			filename += "-" + moment().format("YYYYMMDDHHmmss") + ".csv";
			link.setAttribute("download", filename);
			document.body.appendChild(link); // Required for FF

			link.click(); // This will download the data file
			this.exportButtonDisabled = false;
		},
	},

	watch: {},

	computed: {},
};
</script>

<style></style>
