import moment from "moment";

// const line_datasets = [
//     {
//         label: 'Denbeighshire',
//         data: [ // This is where your input data actually goes!
//             // Data in this format works for sparse data, eg. time series or scatter charts
//             {x: new Date('2010-01-01'), y: 3},
//             {x: new Date('2011-01-01'), y: 4},
//             {x: new Date('2012-01-01'), y: 6},
//         ],
//         borderColor: 'red',
//     },
//     {
//         label: 'Powys',
//         data: [
//             {x: new Date('2010-01-01'), y: 2},
//             {x: new Date('2011-01-01'), y: 5},
//             {x: new Date('2012-01-01'), y: 4},
//             {x: new Date('2013-01-01'), y: 4},
//         ],
//         borderColor: 'blue',
//     },
// ]

// const main_line_datasets = [
//     {
//         label: 'Main',
//         data: [
//             {x: new Date('2010-01-01'), y: 2},
//             {x: new Date('2011-01-01'), y: 1},
//             {x: new Date('2012-01-01'), y: 6},
//             {x: new Date('2013-01-01'), y: 3},
//         ],
//         borderColor: 'green',
//     },
// ]

const fsiprofTSToCJSTS = function (prof_domain_entities, domain, dp_data_type, label, colour) {
	let dataseries = {
		label: label,
		borderColor: colour,
		data: [],
	};

	for (let i in prof_domain_entities) {
		let de = prof_domain_entities[i];
		if (de.domain == domain) {
			for (let j in de.latest_data_points) {
				let dp = de.latest_data_points[j];
				if (dp.data_type == dp_data_type) {
					let payload_data = dp.payload.data;
					if (dp_data_type == "on-demand-data-digital-growth-score") {
						payload_data = payload_data.trend_ts;
					}
					for (let k in payload_data) {
						let dataseries_dp = {
							x: moment(payload_data[k].date).toDate(),
							y: payload_data[k].value,
						};
						dataseries.data.push(dataseries_dp);
					}
					break;
				}
			}
		}
	}
	return dataseries;
};

export { fsiprofTSToCJSTS };
// export default funcs
