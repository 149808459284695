<template>
	<canvas :id="canvasId"></canvas>
</template>

<script>
import "chartjs-adapter-moment";
import Chart from "chart.js/auto";

function makeid(length) {
	var result = "";
	var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const default_lineoptions = {
	tooltips: {
		enabled: false,
	},
	animation: false,
	scales: {
		x: {
			type: "time",
			time: {
				unit: "year",
			},
			title: {
				display: true,
				text: "Date",
			},
		},
	},
	plugins: {
		legend: {
			position: "bottom",
			labels: {
				boxWidth: 20,
			},
		},
	},
};

export default {
	props: ["maindata", "data", "options"],
	data() {
		return {
			canvasId: makeid(10),
		};
	},
	mounted() {
		this.makeChart();
	},

	methods: {
		async makeChart() {
			let processed_maindata = [];
			if (this.maindata) {
				processed_maindata = this.maindata;
				for (let i in processed_maindata) {
					processed_maindata[i].backgroundColor = processed_maindata[i].borderColor;
					// processed_maindata[i].radius = pointSize
					// processed_maindata[i].tension = 0.1
					processed_maindata[i].borderWidth = 2;
					processed_maindata[i].hitRadius = 0;
				}
			}

			let processed_data = this.data;
			for (let i in processed_data) {
				processed_data[i].backgroundColor = processed_data[i].borderColor;
				// processed_data[i].radius = pointSize
				// processed_data[i].tension = 0.1
				processed_data[i].borderDash = [2, 4];
				processed_data[i].borderWidth = 2;
				processed_data[i].hitRadius = 0;
			}

			let data = {
				datasets: processed_maindata.concat(processed_data),
			};

			let ctx = await document.getElementById(this.canvasId).getContext("2d");
			let chart = new Chart(ctx, {
				type: "bar",
				data: data,
				options: default_lineoptions,
			});
		},
	},
};
</script>
