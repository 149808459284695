<template>
	<!-- <div class="flex flex-wrap"> -->
	<!-- <div class="w-full text-center"> -->
	<button
		ref="btnRef"
		v-on:click="togglePopover()"
		class="active:bg-gray-500 text-gray-400 font-bold text-sm px-4 py-3 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
		type="button">
		Info
	</button>
	<div
		ref="popoverRef"
		v-bind:class="{ hidden: !popoverShow, block: popoverShow }"
		class="bg-gray-500 border-0 mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
		<div>
			<div
				class="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg">
				{{ title }}
			</div>
			<div class="text-white p-3">
				{{ text }}
			</div>
		</div>
	</div>
	<!-- </div> -->
	<!-- </div> -->
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
	data() {
		return {
			popoverShow: false,
		};
	},
	props: ["title", "text"],
	methods: {
		togglePopover: function () {
			if (this.popoverShow) {
				this.popoverShow = false;
			} else {
				this.popoverShow = true;
				createPopper(this.$refs.btnRef, this.$refs.popoverRef, {
					placement: "bottom",
				});
			}
		},
	},
};
</script>
