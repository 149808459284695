<template>
	<div class="border rounded mt-4 mr-4 p-4 relative bg-white shadow">
		<div v-if="title">
			<div class="text-sm text-center font-bold">
				{{ title }}
			</div>
			<div v-if="infoTitle" class="absolute top-0 left-0 w-5/12">
				<InfoPopover :title="infoTitle" :text="infoText" />
			</div>
		</div>

		<div class="absolute top-0 right-0 m-3">
			<v-button class="btn btn-small btn-action-outline" @click="exportChart">Export</v-button>
		</div>

		<div v-if="tooMuchData">
			<div class="m-5 text-lg text-center font-bold">Too many domains to show</div>
		</div>

		<div v-if="noDomains">
			<div class="m-5 text-lg text-center font-bold">No data to show</div>
		</div>

		<div class="mt-5" v-if="!tooMuchData && !noDomains">
			<CJSLineTS
				v-if="chartType == 'line'"
				:maindata="mainlinedata"
				:data="linedata"
				:key="chartComponentKey"
				:pointSize="pointSize" />
			<CJSBarTS v-if="chartType == 'bar'" :maindata="mainlinedata" :data="linedata" :key="chartComponentKey" />
			<CJSHBarTS
				v-if="chartType == 'hbar'"
				:maindata="mainlinedata"
				:data="linedata"
				:key="chartComponentKey"
				:xmin="xmin"
				:xmax="xmax" />
			<CJSBarCategoryTS
				v-if="chartType == 'line-category'"
				:categories="categories"
				:maindata="mainlinedata"
				:data="linedata"
				:key="chartComponentKey" />
		</div>

		<div class="font-thin absolute bottom-0 right-0 pr-4 pb-1" style="font-size: 0.5rem">
			© {{ new Date().getFullYear() }} Network Praxis - Foresight SI Company Analysis Charting
		</div>
	</div>
</template>

<script>
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import fsischedulerapi from "@/services/api-clients/fsischeduler";
import InfoPopover from "@/components/ui/InfoPopover";
import CJSLineTS from "@/components/ui/charts/CJSLineTS";
import CJSBarTS from "@/components/ui/charts/CJSBarTS";
import CJSHBarTS from "@/components/ui/charts/CJSHBarTS";
import CJSBarCategoryTS from "@/components/ui/charts/CJSBarCategoryTS";
import Button from "@/components/ui/Button";
import { fsiprofTSToCJSTS } from "@/components/ui/charts/cjs-utils";
import moment from "moment";

const maxDataLines = 10;

const infoTexts = {
	"on-demand-data-digital-growth-score": {
		title: "Digital Growth",
		text: "A measure of how a website has grown or shrunk over time.",
	},
	"on-demand-compiled-data-digital-maturity-score": {
		title: "Digital Maturity",
		text: "A measure of how advanced the technologies are on a website.",
	},
	"on-demand-compiled-data-esg-posture-score": {
		title: "ESG",
		text: "Environment, Social responsibility & Governance. A measure of how closely an organisation aligns itself with ESG related issues and topics. Mean centred for each company each year.",
	},
	"on-demand-compiled-data-innovation-net-grade": {
		title: "Innovation",
		text: "A measure of the innovative capacity of an organisation - graded E (lowest) to A (highest).",
	},
	"on-demand-compiled-data-web-services-count": {
		title: "Web Services/Technologies Count",
		text: "The number of services and technologies that are operating on an organisation's website.",
	},
};

const pull_ready_data_domains = function (domain_res) {
	let ready_domains = [];
	for (let i in domain_res) {
		if (domain_res[i].data_status == "ready") {
			ready_domains.push(domain_res[i].domain);
		}
	}
	return ready_domains;
};

const checkArrayUnique = function (arr) {
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}
	return arr.filter(onlyUnique);
};

const colours_ts_data = [
	// These are colour blind safe
	"#d55e00",
	"#cc79a7",
	"#0072b2",
	"#f0e442",
	"#009e73",

	// These are additional colours in case we have more lines
	"#3fbfbf",
	"#BF3FBF",
	"#BF3F3F",
	"#BFBF3F",
	"#3FBF3F",
];

const pull_chart_data = async function (fsi_prof_response, main_domains, other_domains, dp_data_type) {
	let domains = checkArrayUnique(main_domains.concat(other_domains));

	if (fsi_prof_response == null) {
		fsi_prof_response = await fsiprofessorapi.searchDomains(domains);
	}

	let cjs_data = [];
	for (let i = 0; i < other_domains.length; i++) {
		if (main_domains.include(other_domains[i])) {
			continue;
		}
		let cjs_dataseries = fsiprofTSToCJSTS(
			fsi_prof_response.data.domain_entities,
			other_domains[i],
			dp_data_type,
			other_domains[i],
			colours_ts_data[i]
		);
		if (cjs_dataseries) {
			cjs_data.push(cjs_dataseries);
		}
	}

	let cjs_maindata = [];
	for (let i = 0; i < main_domains.length; i++) {
		let cjs_dataseries = fsiprofTSToCJSTS(
			fsi_prof_response.data.domain_entities,
			main_domains[i],
			dp_data_type,
			main_domains[i],
			colours_ts_data[i + other_domains.length]
		);
		if (cjs_dataseries) {
			cjs_maindata.push(cjs_dataseries);
		}
	}

	return [cjs_data, cjs_maindata];
};

export default {
	created() {},

	mounted() {
		let infoBoxInfo = infoTexts[this.metric];
		if (infoBoxInfo) {
			this.infoTitle = infoBoxInfo.title;
			this.infoText = infoBoxInfo.text;
		}

		let checked_domains = checkArrayUnique(this.domains);
		if (checked_domains.length > maxDataLines) {
			this.tooMuchData = true;
		} else if (checked_domains.length == 0) {
			this.noDomains = true;
		} else {
			this.getProfData(checked_domains);
		}
	},

	components: {
		InfoPopover,
		CJSLineTS,
		CJSBarTS,
		CJSHBarTS,
		CJSBarCategoryTS,
		"v-button": Button,
	},

	props: [
		"title",
		"chartType",
		"categories",
		"domains",
		"metric",
		"xmin",
		"xmax",
		"pointSize",
		"checkedDomainData",
		"fsiProfResponse",
	],

	data() {
		return {
			infoTitle: "",
			infoText: "",
			tooMuchData: false,
			noDomains: false,
			linedata: [],
			mainlinedata: [],
			chartComponentKey: 0,
		};
	},

	methods: {
		async checkDomainData(domains) {
			if (this.checkedDomainData) {
				return this.checkedDomainData;
			} else {
				let res = await fsischedulerapi.bulkDomainDataCheck(domains);
				let domain_res = {};
				for (let i in res.data.domain_data) {
					domain_res[res.data.domain_data[i].domain] = res.data.domain_data[i];
				}

				return domain_res;
			}
		},

		async getProfData(domains) {
			let domain_res = await this.checkDomainData(domains);
			let ready_domains = pull_ready_data_domains(domain_res);

			let res = await pull_chart_data(this.fsiProfResponse, ready_domains, [], this.metric);
			this.linedata = res[0];
			this.mainlinedata = res[1];
			this.chartComponentKey += 1;
		},

		async exportChart() {
			let keys = ["domain", "metric", "date", "value"];
			let dataPoints = [];

			for (let i in this.mainlinedata) {
				for (let j in this.mainlinedata[i].data) {
					let dataPoint = {
						domain: this.mainlinedata[i].label,
						metric: this.metric,
					};

					if (this.chartType == "hbar") {
						(dataPoint.date = moment(this.mainlinedata[i].data[j].y).format("YYYY-MM")),
							(dataPoint.value = this.mainlinedata[i].data[j].x);
					} else {
						(dataPoint.date = moment(this.mainlinedata[i].data[j].x).format("YYYY-MM")),
							(dataPoint.value = this.mainlinedata[i].data[j].y);
					}

					dataPoints.push(dataPoint);
				}
			}

			// Write the CSV
			let keys_string = keys.join(",");
			let csvContent = "data:text/csv;charset=utf-8," + keys_string + "\r\n";

			dataPoints.forEach(function (dp) {
				let row = [];
				for (var k in keys) {
					let value = "";
					if (dp[keys[k]] != null) {
						value = String(dp[keys[k]]).replaceAll(",", "");
					}
					row.push(value);
				}
				csvContent += row.join(",") + "\r\n";
			});

			var encodedUri = encodeURI(csvContent);
			var link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			let filename = "FSI-ChartExport-" + this.metric + "-" + moment().format("YYYYMMDDHHmmss") + ".csv";
			link.setAttribute("download", filename);
			document.body.appendChild(link); // Required for FF

			link.click(); // This will download the data file
		},
	},

	watch: {},

	computed: {},
};
</script>

<style></style>
