<template>
	<div>
		<div class="hidden lg:block">
			<div class="flex mt-8 mb-5 align-middle">
				<div class="text-sm font-bold">Chart size:</div>
				<div class="flex ml-3">
					<div class="cursor-pointer hover:opacity-75 border-2 rounded p-2 mx-1" @click="decreaseChartSize">
						<span v-html="minus_icon"></span>
					</div>
					<div class="cursor-pointer hover:opacity-75 border-2 rounded p-2 mx-1" @click="increaseChartSize">
						<span v-html="plus_icon"></span>
					</div>
				</div>
			</div>
		</div>

		<Tabs class="border-t" v-if="!gettingData">
			<Tab name="Digital trends" :selected="true">
				<div class="mt-5">
					<div
						v-if="checkedDomainData && fsiProfResponse"
						class="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4">
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="line"
								title="Digital Growth"
								:domains="domains"
								metric="on-demand-data-digital-growth-score"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse"
								:pointSize="0" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="line"
								title="Digital Maturity"
								:domains="domains"
								metric="on-demand-compiled-data-digital-maturity-score"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse"
								:pointSize="2" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="line"
								title="Internal links"
								:domains="domains"
								metric="on-demand-compiled-data-webpage-links-num_canonical_domain_links"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse"
								:pointSize="2" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="line"
								title="External links"
								:domains="domains"
								metric="on-demand-compiled-data-webpage-links-num_external_links"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse"
								:pointSize="2" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="bar"
								title="Web Services/Technologies Count"
								:domains="domains"
								metric="on-demand-compiled-data-web-services-count"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse"
								:pointSize="2" />
						</div>
					</div>
				</div>
			</Tab>

			<Tab name="ESG Posture">
				<div class="mt-5">
					<div
						v-if="checkedDomainData && fsiProfResponse"
						class="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4">
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="hbar"
								title="ESG - Combined"
								:domains="domains"
								metric="on-demand-compiled-data-esg-posture-score"
								xmin="-1"
								xmax="1"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="hbar"
								title="Environment"
								:domains="domains"
								metric="on-demand-compiled-data-esg-posture-E_score"
								xmin="-1"
								xmax="1"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="hbar"
								title="Social Responsibility"
								:domains="domains"
								metric="on-demand-compiled-data-esg-posture-S_score"
								xmin="-1"
								xmax="1"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="hbar"
								title="Governance"
								:domains="domains"
								metric="on-demand-compiled-data-esg-posture-G_score"
								xmin="-1"
								xmax="1"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
					</div>
				</div>
			</Tab>

			<Tab name="Export/Internationalisation">
				<div class="mt-5">
					<div
						v-if="checkedDomainData && fsiProfResponse"
						class="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4">
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="bar"
								title="International website domains"
								:domains="domains"
								metric="on-demand-compiled-data-webpage-links-num_domain_tlds"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="bar"
								title="International phone numbers"
								:domains="domains"
								metric="on-demand-compiled-data-phone-numbers-num_regions"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="bar"
								title="Number of languages"
								:domains="domains"
								metric="on-demand-compiled-data-languages-num_languages"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
					</div>
				</div>
			</Tab>

			<Tab name="Innovation">
				<div class="mt-5">
					<div
						v-if="checkedDomainData && fsiProfResponse"
						class="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4">
						<div
							v-bind:class="{
								'col-span-2': chartSize == 2,
								'col-span-3': chartSize == 3,
								'col-span-4': chartSize == 4,
							}">
							<DomainMetricView
								chartType="line-category"
								:categories="['', 'E', 'D', 'C', 'B', 'A']"
								title="Capacity for innovation"
								:domains="domains"
								metric="on-demand-compiled-data-innovation-net-grade"
								:checkedDomainData="checkedDomainData"
								:fsiProfResponse="fsiProfResponse" />
						</div>
					</div>
				</div>
			</Tab>
		</Tabs>
	</div>
</template>

<script>
import DomainMetricView from "@/components/projects/company-analysis/DomainMetricView";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";
import fsischedulerapi from "@/services/api-clients/fsischeduler";

const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`;
const minus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`;

const maxDataLines = 10;

const checkArrayUnique = function (arr) {
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}
	return arr.filter(onlyUnique);
};

const pull_ready_data_domains = function (domain_res) {
	let ready_domains = [];
	for (let i in domain_res) {
		if (domain_res[i].data_status == "ready") {
			ready_domains.push(domain_res[i].domain);
		}
	}
	return ready_domains;
};

export default {
	created() {},

	mounted() {
		this.gatherDomainData();
	},

	components: {
		DomainMetricView,
		Tabs,
		Tab,
	},

	props: ["domains"],

	data() {
		return {
			gettingData: false,
			checkedDomainData: null,
			fsiProfResponse: null,
			chartSize: 2,
			plus_icon,
			minus_icon,
		};
	},

	methods: {
		async gatherDomainData() {
			this.gettingData = true;
			let checked_domains = checkArrayUnique(this.domains);
			if (checked_domains.length > 0 && checked_domains.length <= maxDataLines) {
				this.checkedDomainData = await this.checkDomainData(checked_domains);
				let ready_domains = pull_ready_data_domains(this.checkedDomainData);
				this.fsiProfResponse = await fsiprofessorapi.searchDomains(ready_domains);
			}
			this.gettingData = false;
		},

		async checkDomainData(domains) {
			let res = await fsischedulerapi.bulkDomainDataCheck(domains);
			let domain_res = {};
			for (let i in res.data.domain_data) {
				domain_res[res.data.domain_data[i].domain] = res.data.domain_data[i];
			}

			return domain_res;
		},

		decreaseChartSize() {
			this.chartSize -= 1;
			if (this.chartSize < 2) {
				this.chartSize = 2;
			}
		},
		increaseChartSize() {
			this.chartSize += 1;
			if (this.chartSize > 4) {
				this.chartSize = 4;
			}
		},
	},

	watch: {},

	computed: {},
};
</script>

<style></style>
